// Action Types
const SET_COUPON_LOADING = 'SET_COUPON_LOADING';
const SET_COUPON_ERROR = 'SET_COUPON_ERROR';
const SET_DISCOUNT = 'SET_DISCOUNT';
const CLEAR_DISCOUNT = 'CLEAR_DISCOUNT';

// Initial State
// Feel free to add or remove fields as needed for your logic
const initialState: any = {
  couponLoading: false,
  couponError: null,
  // Type can be 'delivery', 'product', 'total', etc. (or null if no coupon yet)
  discountType: null,
  // Distinguish absolute vs. percentage
  isAbsolute: false,
  // The discount value (e.g. 5 for €5 off, or 10 for 10% off)
  discountValue: 0,
  couponVal: '',
  coupon_id: null,
};

// Reducer
export function discountReducer(
  state = initialState,
  action: { type: string; payload?: any },
) {
  switch (action.type) {
    case SET_COUPON_LOADING:
      return {
        ...state,
        couponLoading: action.payload.loading,
      };

    case SET_COUPON_ERROR:
      return {
        ...state,
        couponError: action.payload.error,
      };

    case SET_DISCOUNT:
      return {
        ...state,
        // e.g. { discountType, isAbsolute, discountValue }
        discountType: action.payload.discountType,
        isAbsolute: action.payload.isAbsolute,
        discountValue: action.payload.discountValue,
        couponError: null, // reset any previous error
        couponVal: action.payload.val,
        coupon_id: action.payload.coupon_id,
      };

    case CLEAR_DISCOUNT:
      return {
        ...state,
        discountType: null,
        isAbsolute: false,
        discountValue: 0,
        couponError: null,
        couponVal: '',
        coupon_id: null,
      };

    default:
      return state;
  }
}

// Action Creators
export function setCouponLoading(loading: boolean) {
  return { type: SET_COUPON_LOADING, payload: { loading } };
}

export function setCouponError(error: string) {
  return { type: SET_COUPON_ERROR, payload: { error } };
}

/**
 * @param discountType   e.g. 'delivery', 'product', 'total'
 * @param isAbsolute     true if discountValue is an absolute amount (e.g. €5),
 *                       false if it's a percentage (e.g. 10%).
 * @param discountValue  the numeric value of the discount
 */
export function setDiscount(
  discountType: string,
  isAbsolute: boolean,
  discountValue: number,
  val: any,
  coupon_id: any,
) {
  return {
    type: SET_DISCOUNT,
    payload: { discountType, isAbsolute, discountValue, val, coupon_id },
  };
}

/**
 * Clears all discount info (as if no coupon is applied).
 */
export function clearDiscount() {
  return { type: CLEAR_DISCOUNT };
}
